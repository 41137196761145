<template>
  <div id="app">
    <header>
      <div id="nav">
        <b-navbar toggleable="lg" type="light" variant="mdh">
          <b-navbar-brand>
            <img src="./assets/logo-reverse.png" style="height:38px;" alt="Minnesota Department of Health Logo"/>
          </b-navbar-brand>
        </b-navbar>
      </div>
    </header>



    <main id="main-content">
        <router-view/>
    </main>

    <!-- <footer>
      <div class="mt-5">
        <hr/>
        <div class="fluid-container footer justify-content-center">
          <p class="text-center" style="font-size:.85em;">
            <span  v-if="(authenticated && !hasRole('mdh-staff') && !hasRole('mdh-admin') && !hasRole('ie-staff')) ||!authenticated">
              For questions, please call the MN Department of Health Help Desk at 651-501-5555 -
            </span>
            <span>v{{appVersion}}</span>
          </p>
        </div> 
      </div>
    </footer> -->

  </div>
</template>

<script>
  import LocalStorageService from '@/common/LocalStorageService';
  import {version} from '../package.json';

  export default {
    metaInfo: {
      title: 'Welcome',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
    computed: {
      // authenticated: function() {
      //   if (this.$keycloak) {
      //     return this.$keycloak.authenticated;
      //   } else {
      //     return false;
      //   }
      // },
      appVersion: function() {
        return version;
        }
    },
    // methods: {
    //   login () {
    //     const localStorageService = LocalStorageService.getService();
    //     localStorageService.clearAuthTokens();
    //     const loginUrl = this.$keycloak.createLoginUrl()
    //     window.location.replace(loginUrl)
    //   },
    //   logout() {
    //     const localStorageService = LocalStorageService.getService()
    //     localStorageService.clearAuthTokens()
    //     this.$store.dispatch('resetState')
    //     this.$keycloak.clearToken()
    //     this.$keycloak.logoutFn()
    //   },
    //   hasRole(roles) {
    //     if (this.$keycloak) {
    //       return this.$keycloak.hasResourceRole(roles)
    //     } else {
    //       return false;
    //     }
    //   }
    // },
  }
  </script>

<style lang="scss">

  @import "assets/_custom.scss";
  @import "vue-select/src/scss/vue-select.scss";
  @import "~bootstrap/scss/bootstrap.scss";
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
#app {
  font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1, .h1 {
  font-size: 30px;
  font-weight: 600;
}

h2, .h2 {
  font-size: 24px;
  font-weight: 600;
}

h3, .h3 {
  font-size: 18px;
  font-weight: 600;
}

ul {
  list-style-type: none;
  padding: 0;
}


.bg-mdh {
  background-color: rgb(0, 56, 101);
}
#nav {
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}
#nav a {
  font-weight: normal;
  color: white;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav  a.dropdown-item {
  color: black !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.colWidth40 {
  width: 40%;
}

.is-invalid .d-block {
    color: #ef0000;
}

.label-required::after {
  color: #0a8927;
  font-size: .7em;
  text-transform: uppercase;
  margin-left: 19px;
  content:" required";

}

.wizard-card-footer div span {
  outline: none;
}

.wizard-card-footer div span:focus button {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(155, 89, 182, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(155, 89, 182, 0.25);
}

.wizard-nav li:has(.disabled) {
  user-focus: none;
 }

 .card {
   margin-bottom: 2em;
 }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-light .navbar-toggler {
    border-color: white;
  }

  .sr-caption caption {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .sr-caption caption:active,
  .sr-caption caption:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
</style>
