export const constants = {

    data() {
        return {
            providerList: [
                { value: "_", text: "All Provider Types" },
                { value: "ALL_", text: "Assisted Living Facilities"},
                { value: "BCH_", text: "Boarding Care Homes"},
                { value: "HCP_", text: "Home Care and Home Health Agencies"},
                { value: "HSPICE_", text: "Hospices"},
                { value: "HOSP_", text: "Hospitals"},
                { value: "NH_", text: "Nursing Homes" },
                { value: "SLF_", text: "Supervised Living Facilities" },
              ],
              
              filterTypes: [
                { value: null, text: "All"},
                { value: "complaints", text: "Investigations" },
                { value: "surveys", text: "Evaluations"}
              ],

              investigationFindings: [
                { value: null, text: "All"},
                { value: "SUBSTANTIATED", text: "Substantiated"},
                { value: "INCONCLUSIVE", text: "Inconclusive" },
                { value: "UNSUBSTANTIATED", text: "Unsubstantiated"}
              ],

              counties: [
                { value: null, text: "All Counties"},
                "Aitkin",
                "Anoka",
                "Becker",
                "Beltrami",
                "Benton",
                "Big Stone",
                "Blue Earth",
                "Brown",
                "Carlton",
                "Carver",
                "Cass",
                "Chippewa",
                "Chisago",
                "Clay",
                "Clearwater",
                "Cook",
                "Cottonwood",
                "Crow Wing",
                "Dakota",
                "Dodge",
                "Douglas",
                "Faribault",
                "Fillmore",
                "Freeborn",
                "Goodhue",
                "Grant",
                "Hennepin",
                "Houston",
                "Hubbard",
                "Iowa (State)",
                "Isanti",
                "Itasca",
                "Jackson",
                "Kanabec",
                "Kandiyohi",
                "Kittson",
                "Koochiching",
                "Lac Qui Parle",
                "Lake",
                "Lake of the Woods",
                "Lesueur",
                "Lincoln",
                "Lyon",
                "Mcleod",
                "Mahnomen",
                "Marshall",
                "Martin",
                "Meeker",
                "Mille Lacs",
                "Morrison",
                "Mower",
                "Murray",
                "Nicollet",
                "Nobles",
                "Norman",
                "North Dakota (State)",
                "Olmsted",
                "Otter Tail",
                "Pennington",
                "Pine",
                "Pipestone",
                "Polk",
                "Pope",
                "Ramsey",
                "Red Lake",
                "Redwood",
                "Renville",
                "Rice",
                "Rock",
                "Roseau",
                "Saint Louis",
                "Scott",
                "Sherburne",
                "Sibley",
                "South Dakota (State)",
                "Stearns",
                "Steele",
                "Stevens",
                "Swift",
                "Todd",
                "Traverse",
                "Wabasha",
                "Wadena",
                "Waseca",
                "Washington",
                "Watonwan",
                "Wilkin",
                "Winona",
                "Wisconsin (State)",
                "Wright",
                "Yellow Medicine",
              ],
        }
    }
}