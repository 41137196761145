<template>
  <div
    class="home mx-auto w-75"
    style="margin-top: 4rem; margin-bottom: 35px"
    fluid
  >
    <div>
      <h1 class="text-center">Health Care Provider Evaluation and Investigation Results</h1>
      <p style="color: red;" class="text-center">This website is currently under construction.</p>
          <p>
            The Minnesota Department of Health, Health Regulation Division developed this site to improve public access to health care provider evaluation and investigation information.
          </p>
        <ul style="list-style-type: circle;">
          <li>
            <strong>An evaluation</strong> is a process regulated by the Health Regulation Division to ensure that licensees are compliant with the laws, rules, and certifications applicable to the type of care and services authorized under the license/certification.
          </li>
          <li>
            <strong>An investigation</strong> may occur when the Health Regulation Division receives allegations that care
            and services are not being provided by licensees as required by law, which includes allegations of abuse, neglect, or financial exploitation.
          </li>
      </ul>
      <p>
        <strong>To request an accessible version</strong> of investigation or evaluation results, please call: 651-201-4200.
      </p>
      <p>
        <strong>Investigation and evaluation results from facilities that have closed are not available through this search page.</strong> To request information about closed facilities, please submit a <a href="https://www.health.state.mn.us/data/datapractices/index.html">Data Practices Request</a>.
      </p>
      <p><strong>Investigations are maintained</strong> for 7 years for substantiated findings, 4 years for inconclusive findings, and 3 years for unsubstantiated findings.</p>
      <p><strong>We do not post evaluations</strong> for Boarding Care Homes, Hospices, Hospitals, and Supervised Living Facilities. To obtain evaluations for these facilities please contact MDH.</p>
    </div>

    <!-- Regular Search -->
    <b-form @submit.prevent="submit">
      <b-card bg-variant="light" no-body footer-tag="footer">
        <b-card-body>
          <div role="group" class="form-row form-group">
            <h2 class="col-form-label text-sm-left">
              <b> Search Criteria: </b>
            </h2>
          </div>
          <b-row>
            <b-col cols="12" lg="4">
              <BaseSelector
                setId="providerType-input"
                v-model="criteria.providerType"
                :v="$v.criteria.providerType"
                label="Provider:"
                :selectOption="true"
                :options="providerList"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="4">
              <BaseTextInput
                setId="city-input"
                v-model="criteria.city"
                :v="$v.criteria.city"
                label="City:"
                slug="city"
              />
            </b-col>
            <b-col cols="12" lg="4">
              <BaseSelector
                setId="country-input"
                v-model="criteria.county"
                :v="$v.criteria.county"
                label="County:"
                slug="county"
                :selectOption="false"
                :options="counties"
              />
            </b-col>
            <b-col cols="12" lg="4">
              <BaseTextInput
                setId="zipCode-input"
                v-model="criteria.zipCode"
                :v="$v.criteria.zipCode"
                label="Zip Code:"
                slug="zipCode"
              />
            </b-col>
            <b-col cols="6" lg="2">
              <BaseSelector
                  setId="filterType-input"
                  v-model="criteria.nameCriteria"
                  :v="$v.criteria.nameCriteria"
                  label="Provider Name:"
                  slug="providerNameFilter"
                  :options="nameCriteriaOptions"
                  />
            </b-col>
            <b-col cols="12" lg="4">
              <BaseTextInput
                setId="providerName-input"
                v-model="criteria.providerName"
                :v="$v.criteria.providerName"
                :label="'\xa0'"
                slug="providerName"
              />
            </b-col>
            <b-col cols="12" lg="4">
              <BaseTextInput
                setId="providerId-input"
                v-model="criteria.providerId"
                :v="$v.criteria.providerId"
                label="Provider HFID:"
                slug="providerId"
              />
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer class="bg-secondary">
          <div class="text-center w-100 text-white" aria-live="polite" @click="showAdvSearch = !showAdvSearch" @keypress="showAdvSearch = !showAdvSearch"  tabindex="0">
            Advanced Search Options
            <b-icon class="ml-3" v-if="showAdvSearch" icon="chevron-up" />
            <b-icon class="ml-3" v-else icon="chevron-down" />
          </div>
        </b-card-footer>
      </b-card>

      <!-- Advance Search -->
      <b-card style="margin-top: -2rem" v-if="showAdvSearch">
        <div role="group" class="form-row form-group">
          <h2 class="col-form-label text-sm-left">
            <b> Select a Filter Type:</b>
          </h2>
        </div>
        <b-row>
          <b-col cols="12" lg="6">
            <BaseSelector
              setId="filterType-input"
              v-model="filterType"
              :v="$v.filterType"
              label="Filter Type:"
              :selectOption="false"
              slug="all"
              :options="filterTypes"
            />
          </b-col>
          <b-col cols="12" lg="6" v-if="filterType === 'complaints'">
            <BaseSelector
              setId="investigationFindings-input"
              v-model="investigationFinding"
              :v="$v.investigationFinding"
              label="Investigation Finding:"
              :selectOption="false"
              slug="all"
              :options="investigationFindings"
            />
          </b-col>
        </b-row>
        <div>
          <div role="group" class="form-row form-group">
            <h2 class="col-form-label text-sm-left">
              <b> Filter by Date Ranges: </b>
            </h2>
          </div>
          <b-row>
            <b-col cols="12" lg="4">
              <BaseDateOfBirthInput
                setId="startDate"
                v-model="criteria.postedStartDate"
                :v="$v.criteria.postedStartDate"
                label="Posted On Start Date:"
              />
            </b-col>
            <b-col cols="12" lg="4">
              <BaseDateOfBirthInput
                setId="endDate"
                v-model="criteria.postedEndDate"
                :v="$v.criteria.postedEndDate"
                label="Posted On End Date:"
              />
            </b-col>
          </b-row>
          <b-row>
          <b-col cols="12" lg="4">
            <BaseDateOfBirthInput
                    setId="startDate"
                    v-model="criteria.concludedStartDate"
                    :v="$v.criteria.concludedStartDate"
                    label="Concluded On Start Date:"
            />
          </b-col>
          <b-col cols="12" lg="4">
            <BaseDateOfBirthInput
                    setId="endDate"
                    v-model="criteria.concludedEndDate"
                    :v="$v.criteria.concludedEndDate"
                    label="Concluded On End Date:"
            />
          </b-col>
          </b-row>
        </div>
        <div class="text-center">
          <b-button style="margin-right: 18px" @click="clearDates" id="clear-date">
            Clear Dates
          </b-button>
        </div>
      </b-card>
      <b-card style="margin-top: -2rem" bg-variant="light">
          <div class="text-center" style="margin:0">
            <b-button style="margin-right: 18px" @click="reset()">
              Reset
            </b-button>
            <b-button style="margin-right: 18px" @click="submit()" type="submit" variant="primary">
              Submit
            </b-button>
          </div>
      </b-card>
    </b-form>
    <div aria-live="polite">
      <b-card v-if="showResults" class="my-5" bg-variant="light" id="results" aria-live="polite" aria-relevant="all">
        <div>
          <div v-if="isLoading"  class="text-center text-danger my-2" aria-live="assertive" aria-relevant="all">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2" aria-live="polite" aria-relevant="all">Loading, please wait...</strong>
          </div>
          <p v-if="!isLoading" class="text-center" aria-live="assertive" aria-relevant="text">
            Your selection found {{ results.length }} results
          </p>
          <div class="mx-auto align-items-end" aria-live="off">
            <div>
              Sorting By: <b>{{ formatSortByField(sortBy) }} ,</b> Sort Direction:
              <b>{{ sortDesc ? "Descending" : "Ascending" }}</b>
            </div>
          </div>
          <b-table
            show-empty
            :fields="fields"
            :items="results"
            :busy="isLoading"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-icon-left
            :current-page="currentPage"
            responsive="sm"
            aria-live="off"
          >


            <template v-slot:empty> </template>
            <template #cell(providerType)="data">{{parseProviderType(data.item.providerType)}}</template>
            <template #cell(nbr)="data">
              {{data.item.nbr}}{{data.item.nbr2?' / '+data.item.nbr2:''}}
            </template>
            <template #cell(status)="data">
              <div :id="'table-status-'+data.item.nbr">{{data.item.status}}{{data.item.status2?' / '+data.item.status2:''}}</div>
              <b-tooltip :target="'table-status-'+data.item.nbr" triggers="hover">{{investigationToolTips(data.item.status,data.item.status2)}}</b-tooltip>
            </template>
            <template #cell(resolvedDate)="data">
              {{data.item.resolvedDate}}{{data.item.resolvedDate2?' / '+data.item.resolvedDate2:''}}
            </template>

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.city }} {{ data.item.state }}
            </template>

            <template #cell(link)="data">
              <b-link @click="showConfirmNewTabModal(data.item.nbr)">View {{data.item.nbr}}</b-link>
              <b-modal :id="'confirm-new-tab-modal'+data.item.nbr" hide-footer hide-header centered>
                <div class="card">
                  <div class="card-header">Open link in new tab?</div>
                  <div class="card-body">
                    <form ref="form" @submit.prevent="openLink(data.item.link)">
                      <p>You are about to open report {{data.item.nbr}} regarding care facility {{data.item.providerName}} in a new tab. Please click confirm to open in a new tab, or cancel to go back.</p>
                      <div>
                        <b-button @click="closeConfirmNewTabModal(data.item.nbr)" style="margin-right:15px;" variant="secondary">Cancel</b-button>
                        <b-button type="submit" variant="primary">Confirm</b-button>
                      </div>
                    </form>
                  </div>
                </div>
              </b-modal>
              <br v-if="data.item.nbr2"/>
              <b-link v-if="data.item.nbr2" @click="showConfirmNewTabModal(data.item.nbr2)">View {{data.item.nbr2}}</b-link>
              <b-modal :id="'confirm-new-tab-modal'+data.item.nbr2" hide-footer hide-header centered>
                <div class="card">
                  <div class="card-header">Open link in new tab?</div>
                  <div class="card-body">
                    <form ref="form" @submit.prevent="openLink(data.item.link2)">
                      <p>You are about to open report {{data.item.nbr2}} regarding care facility {{data.item.providerName}} in a new tab. Please click confirm to open in a new tab, or cancel to go back.</p>
                      <div>
                        <b-button @click="closeConfirmNewTabModal(data.item.nbr2)" style="margin-right:15px;" variant="secondary">Cancel</b-button>
                        <b-button type="submit" variant="primary">Confirm</b-button>
                      </div>
                    </form>
                  </div>
                </div>
              </b-modal>
            </template>

            <template #cell(survey)="survey">
              <div v-if="survey.item.inspections !== undefined">
                <div>
                  <b-link @click="showConfirmNewTabModal(survey.item.inspections[0].nbr)">
                    Most Recent Available Evaluation for {{survey.item.providerName}}
                  </b-link>
                  <b-modal :id="'confirm-new-tab-modal'+survey.item.inspections[0].nbr" hide-footer hide-header centered>
                    <div class="card">
                      <div class="card-header">Open link in new tab?</div>
                      <div class="card-body">
                        <form ref="form" @submit.prevent="openLink(survey.item.inspections[0].link)">
                          <p>You are about to open evaluation {{survey.item.inspections[0].nbr}} regarding care facility {{survey.item.providerName}} in a new tab. Please click confirm to open in a new tab, or cancel to go back.</p>
                          <div>
                            <b-button @click="closeConfirmNewTabModal(survey.item.inspections[0].nbr)" style="margin-right:15px;" variant="secondary">Cancel</b-button>
                            <b-button type="submit" variant="primary">Confirm</b-button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </b-modal>
                </div>
                <div v-if="survey.item.inspections.length > 1">
                  |
                  <b-link @click="showConfirmNewTabModal(survey.item.inspections[1].nbr)">
                    Prior Evaluation for {{survey.item.providerName}}
                  </b-link>
                  <b-modal :id="'confirm-new-tab-modal'+survey.item.inspections[1].nbr" hide-footer hide-header centered>
                    <div class="card">
                      <div class="card-header">Open link in new tab?</div>
                      <div class="card-body">
                        <form ref="form" @submit.prevent="openLink(survey.item.inspections[1].link)">
                          <p>You are about to open evaluation {{survey.item.inspections[1].nbr}} regarding care facility {{survey.item.providerName}} in a new tab. Please click confirm to open in a new tab, or cancel to go back.</p>
                          <div>
                            <b-button @click="closeConfirmNewTabModal(survey.item.inspections[1].nbr)" style="margin-right:15px;" variant="secondary">Cancel</b-button>
                            <b-button type="submit" variant="primary">Confirm</b-button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
              <div v-else>
                No evaluation findings found for {{survey.item.providerName}}.
              </div>
            </template>

            <template #cell(complaints)="row">
              <b-button v-if="row.item.complaints!=null && row.item.complaints.length>0" variant="primary" size="sm" @click="info(row.item, $event.target)">
                View Investigations for {{row.item.providerName}}
              </b-button>
              <div v-else>No Investigations found for {{row.item.providerName}}.</div>
            </template>

          </b-table>
          <b-pagination
            v-if="!isLoading && showResults"
            v-model="currentPage"
            :total-rows="results.length"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-3"
            aria-live="off"
          ></b-pagination>
        </div>
      </b-card>
    </div>
    <!-- Info modal -->
    <b-modal
      size="xl"
      :id="infoModal.id"
      centered
      headerClass="border-bottom-0"
      :title="infoModal.title"
      class="modal-dialog modal-content"
      ok-only
    >
      <b-table
        show-empty
        ref="complaints-table"
        class="mx-auto"
        :fields="infoModal.fields"
        :items="infoModal.content.complaints"
        responsive
        style="max-width: 100%; border-top: none"
      >
        <template v-slot:empty>
          <p class="text-center my-2">No results found.</p>
        </template>
        <template #cell(nbr)="data">{{data.item.nbr}}{{data.item.nbr2?' / '+data.item.nbr2:''}}</template>
        <template #cell(status)="data">
          <div :id="'table-status2-'+data.item.nbr">{{data.item.status}}{{data.item.status2?' / '+data.item.status2:''}}</div>
          <b-tooltip :target="'table-status2-'+data.item.nbr" triggers="hover">{{investigationToolTips(data.item.status,data.item.status2)}}</b-tooltip>
        </template>
        <template #cell(resolvedDate)="data">{{data.item.resolvedDate}}{{data.item.resolvedDate2?' / '+data.item.resolvedDate2:''}}</template>
        <template #cell(link)="data">
          <b-link @click="showConfirmNewTabModal(data.item.nbr)">View {{data.item.nbr}}</b-link>
          <b-modal :id="'confirm-new-tab-modal'+data.item.nbr" hide-footer hide-header centered class="modal-dialog modal-content">
            <div class="card">
              <div class="card-header">Open link in new tab?</div>
              <div class="card-body">
              <form ref="form" @submit.prevent="openLink(data.item.link)">
                <p>You are about to open report {{data.item.nbr}} regarding care facility {{data.item.providerName}} in a new tab. Please click confirm to open in a new tab, or cancel to go back.</p>
                <div>
                  <b-button @click="closeConfirmNewTabModal(data.item.nbr)" style="margin-right:15px;" variant="secondary">Cancel</b-button>
                  <b-button type="submit" variant="primary">Confirm</b-button>
                </div>
              </form>
              </div>
          </div>
          </b-modal>
          <br v-if="data.item.nbr2"/>
          <b-link v-if="data.item.nbr2" @click="showConfirmNewTabModal(data.item.nbr2)">View {{data.item.nbr2}}</b-link>
          <b-modal :id="'confirm-new-tab-modal'+data.item.nbr2" hide-footer hide-header centered>
            <div class="card">
              <div class="card-header">Open link in new tab?</div>
              <div class="card-body">
                <form ref="form" @submit.prevent="openLink(data.item.link2)">
                  <p>You are about to open report {{data.item.nbr2}} regarding care facility {{data.item.providerName}} in a new tab. Please click confirm to open in a new tab, or cancel to go back.</p>
                  <div>
                    <b-button @click="closeConfirmNewTabModal(data.item.nbr2)" style="margin-right:15px;" variant="secondary">Cancel</b-button>
                    <b-button type="submit" variant="primary">Confirm</b-button>
                  </div>
                </form>
              </div>
            </div>
          </b-modal>
        </template>
      </b-table>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";

import {required, maxLength, numeric,} from "vuelidate/lib/validators";
import { constants } from "@/mixins/constants.js";
import { constantFields } from "@/mixins/constantFields.js";
import BaseSelector from "../components/templates/input/BaseSelector";
import BaseTextInput from "../components/templates/input/BaseTextInput";
import BaseDateOfBirthInput from "../components/templates/input/BaseDateOfBirthInput";


export default {
  mixins: [constants, constantFields],
  components: {
    BaseTextInput,
    BaseSelector,
    BaseDateOfBirthInput
  },
  name: "Home",
  data() {
    return {
      results: [],
      hasEvals: false,
      all: false,
      showAdvSearch: false,
      showResults: false,

      isLoading: false,
      itemsFound: 0,

      perPage: 10,
      currentPage: 1,

      sortBy: "providerName",
      sortDesc: false,

      criteria: {
        providerType: null,

        county: null,
        city: null,
        zipCode: null,
        providerName: null,
        providerId: null,
        nameCriteria: 'STARTS',
        postedStartDate: null,
        postedEndDate: null,
        concludedStartDate: null,
        concludedEndDate: null,
      },

      filterType: null,
      investigationFinding: null,

      fields: [],

      nameCriteriaOptions:
      [
        {value: 'STARTS',text:'Starts with:'},
        {value: 'ENDS',text:'Ends with:'},
        {value: 'CONTAINS',text:'Contains:'}
      ],


      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
        fields: [
          {
            key: "nbr",
            label: "Report Number",
            sortable: true,
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
          },
          {
            key: "insertDate",
            label: "Posted On",
            sortable: true,
          },
          {
            key: "resolvedDate",
            label: "Concluded On",
            sortable: true,
          },
          {
            key: "link",
            label: "Link to Report",
          },
        ],
      },
    };
  },

  validations() {
    return {
      criteria: {
        providerType: { required},
        county: { required: false},
        nameCriteria: {required: false},
        city: { required: false, maxLength: maxLength(25)},
        zipCode: { numeric, required: false, maxLength: maxLength(5) },
        providerName: { required: false, maxLength: maxLength(25) },
        providerId: { numeric, required: false, maxLength: maxLength(5) },
        postedStartDate: { required: false, maxLength: maxLength(100) },
        postedEndDate: { required: false, maxLength: maxLength(100) },
        concludedStartDate: {required: false, maxLength: maxLength(100)},
        concludedEndDate: {required: false, maxLength: maxLength(100)}
      },
      filterType: { required: false },
      investigationFinding:{required: false}

    };
  },

  methods: {
    openLink(link){
      window.open(link,"_blank");
    },
    showConfirmNewTabModal(number){
      this.$bvModal.show('confirm-new-tab-modal'+number);
    },
    closeConfirmNewTabModal(number){
      this.$bvModal.hide('confirm-new-tab-modal'+number);
    },
    reset() {
      this.showAdvSearch = false;
      this.showResults = false;

      this.filterType = null;
      this.investigationFinding = null;

      this.criteria = {
        providerType: null,

        county: null,
        city: null,
        zipCode: null,
        providerName: null,
        providerId: null,

        startDate: null,
        endDate: null,
      }
      this.hasEvals = false;
      this.all = false;

      this.$nextTick(() => {
        this.$v.$reset();
      });

    },

    // returns a list of surveys based on results
    surveyList(results) {
      var surveryList = [];

      results.map((provider) => {
        if (provider.inspections !== undefined) {
          surveryList = surveryList.concat(provider.inspections);
        }
      });
      return surveryList;
    },

    // returns a list of complaints based on results
    complaintList(results) {
      var complaintList = [];

      results.map((provider) => {
        if (provider.complaints !== undefined) {
          if (this.investigationFinding !== null) {
            provider.complaints = provider.complaints.filter(
              (complaint) => complaint.status === this.investigationFinding
            );
          }
          complaintList = complaintList.concat(provider.complaints);
        }
      });
      return complaintList;
    },
    submit() {
      this.$v.criteria.providerType.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.results = [];

      this.all = this.criteria.providerType === '_';
      this.hasEvals = !(this.criteria.providerType === 'HSPICE_' || this.criteria.providerType === 'HOSP_' || this.criteria.providerType === 'SLF_' || this.criteria.providerType === 'BCH_')

      this.showResults=true;
      this.isLoading=true;

      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/search`, this.criteria, {
          ignoreHeader: true,
        })
        .then((response) => {
          console.log(response);
          this.results = response.data;
          this.currentPage = 1;

          // Advance search filtertype logic
          if (this.filterType === "complaints") {
            this.results = this.complaintList(this.results);
            console.log(this.results);
            this.fields = this.complaintFields;
          } else if (this.filterType === "surveys") {
            this.results = this.surveyList(this.results);
            this.fields = this.surveyFields;
          } else {
            if(this.all){
              this.fields = this.allFieldsFacType;
            }
            else if(this.hasEvals) {
              this.fields = this.allFields;
            }
            else{
              this.fields = this.allFieldsNoSurveys;
            }
          }

          this.isLoading = false;

        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
        });

      window.document.getElementById('results').scrollIntoView();

    },
    info(item, button) {
      this.infoModal.title = `${item.providerName} Investigations:`;
      this.infoModal.content = item;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    clearDates() {
      this.criteria.postedStartDate = null;
      this.criteria.postedEndDate = null;
      this.criteria.concludedStartDate = null;
      this.criteria.concludedEndDate = null;

      this.$nextTick(() => {
        this.$v.criteria.postedStartDate.$reset();
        this.$v.criteria.postedEndDate.$reset();
        this.$v.criteria.concludedStartDate.$reset();
        this.$v.criteria.concludedEndDate.$reset();
      });
    },
    formatSortByField(sortBy){
      if(sortBy === 'id') return 'Provider HFID';
      if(sortBy === 'providerName') return 'Provider Name';
      if(sortBy === 'address') return 'Address';
      return 'N/A';
    },
    investigationToolTips(inv1, inv2){
      let result = '';
      if(inv1==="SUBSTANTIATED"){
        result += "Substantiated - placeholder text."
      }
      if(inv1==="UNSUBSTANTIATED") {
        result += "Unsubstantiated - placeholder text."
      }
      if(inv1==="INCONCLUSIVE"){
        result += "Inconclusive - placeholder text."
      }

      if(inv2!=null && inv1!==inv2){
        result += "\n";
        if(inv2==="SUBSTANTIATED"){
          result += "Substantiated - placeholder text."
        }
        if(inv2==="UNSUBSTANTIATED") {
          result += "Unsubstantiated - placeholder text."
        }
        if(inv2==="INCONCLUSIVE"){
          result += "Inconclusive - placeholder text."
        }
      }
      return result;
    },
    parseProviderType(type){
      if(!type){
        return "Other";
      }
      let split = type.split("_");
      let result = [];
      for(let i=0 ;i<split.length-1;i++){
        console.log(split[i]);
        let search = this.providerList.find(x=>x.value===split[i]+'_');
        if(search){
          result.push(search.text);
        }
         if(result.length===0){
           return "Other"
         }
      }
      return result.join(", ");
    }
  },
  // computed: {
  //   userRole() {
  //     return this.$store.state.userRole;
  //   },
  //   greeting() {
  //     return this.$keycloak.fullName;
  //   },
  // },

  created() {
    document.title = "HCP Evaluation and Investigation Results";
  },
};
</script>

<style>
.sr-only {
  border-top: 3px solid;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: auto;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.modal-dialog {
  min-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.green-header {
  color: #60a609;
}
.badge-success {
  color: #fff;
  background-color: #175e28;
}
a:link{
  color: #3077B4;
}
  a:visited{
    color: #3077B4;
  }

</style>
