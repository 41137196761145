export const constantFields = {

    data() {
        return {
            allFields: [
                {
                  key: "id",
                  label: "Provider HFID",
                  sortable: true,
                },
                {
                  key: "providerName",
                  label: "Provider Name",
                  thStyle: { whiteSpace: "nowrap" },
                  sortable: true,
                },
                {
                  key: "address",
                  label: "Address",
                  sortable: true,
                  thStyle: { whiteSpace: "nowrap" },
                },
                {
                  label: "Evaluations",
                  key: "survey",
                  tdClass: "align-middle",
                },
                {
                  label: "Investigations",
                  key: "complaints",
                  thStyle: { whiteSpace: "nowrap" },
                },
              ],
          allFieldsNoSurveys: [
            {
              key: "id",
              label: "Provider HFID",
              sortable: true,
            },
            {
              key: "providerName",
              label: "Provider Name",
              thStyle: { whiteSpace: "nowrap" },
              sortable: true,
            },
            {
              key: "address",
              label: "Address",
              sortable: true,
              thStyle: { whiteSpace: "nowrap" },
            },
            {
              label: "Investigations",
              key: "complaints",
              thStyle: { whiteSpace: "nowrap" },
            },
          ],
          allFieldsFacType: [
            {
              key: "id",
              label: "Provider HFID",
              sortable: true,
            },
            {
              key: "providerType",
              label: "Provider Type",
              sortable: true,
            },
            {
              key: "providerName",
              label: "Provider Name",
              thStyle: { whiteSpace: "nowrap" },
              sortable: true,
            },
            {
              key: "address",
              label: "Address",
              sortable: true,
              thStyle: { whiteSpace: "nowrap" },
            },
            {
              label: "Evaluations",
              key: "survey",
              tdClass: "align-middle",
            },
            {
              label: "Investigations",
              key: "complaints",
              thStyle: { whiteSpace: "nowrap" },
            },
          ],
        
              surveyFields: [
                {
                  key: "providerId",
                  label: "Provider HFID",
                  sortable: true,
                },
                {
                  key: "providerName",
                  label: "Provider Name",
                  thStyle: { whiteSpace: "nowrap" },
                  sortable: true,
                },
                {
                  key: "nbr",
                  label: "Report Number",
                  sortable: true,
                },
                {
                  key: "insertDate",
                  label: "Posted On",
                  sortable: true,
                },
                {
                  key: "resolvedDate",
                  label: "Concluded On",
                  sortable: true,
                },
                {
                  key: "link",
                  label: "Report Links",
                },
              ],
        
              complaintFields: [
                {
                  key: "providerId",
                  label: "Provider HFID",
                  sortable: true,
                },
                {
                  key: "providerName",
                  label: "Provider Name",
                  thStyle: { whiteSpace: "nowrap" },
                  sortable: true,
                },
                {
                  key: "nbr",
                  label: "Report Number",
                  sortable: true,
                },
                {
                  key: "status",
                  label: "Status",
                  sortable: true,
                },
                {
                  key: "insertDate",
                  label: "Posted On",
                  sortable: true,
                },
                {
                  key: "resolvedDate",
                  label: "Concluded On",
                  sortable: true,
                },
                {
                  key: "link",
                  label: "Investigation Link",
                },
              ],
        
        }
    }
}