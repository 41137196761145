<template>
    <form-group :validator="v" :label="label" :label-for="setId" :description="tip">
        <template slot-scope="{ attrs, listeners }">
            <b-form-select :id="id" :value="value"
                :state="v.$dirty ? !v.$error : null"
                v-bind="attrs"
                v-on="listeners"
                :data-test="slug"
                :tabindex="tabIndex"
                @input="updateValue" @change="updateValue" :options="options">
                <template v-slot:first>
                    <option v-if="selectOption" :value="null" disabled>-- Please select an option --</option>
                </template>
            </b-form-select>
        </template>
    </form-group>
</template>

<script>
import _ from "lodash"

export default {
    props: {
        selectOption: Boolean,
        value: [String, String],
        label: [String],
        tip: [String],
        slug: [String],
        options: Array,
        v: [Object],
        setId: [String],
        tabIndex: [Number]
    },
    created() {
        if (this.value != null) {
            this.updateValue(this.value)
        }
    },
    computed: {
        id() {
            if (this.setId === null) {
                return _.uniqueId('selector')
            } else {
                return this.setId
            }
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        }
    }
}
</script>
<style>
.form-group > label {
    font-size: inherit;
    font-weight: 0;
}
legend {
    font-size: inherit;
    font-weight: 0;
}
</style>