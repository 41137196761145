import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import LocalStorageService from "@/common/LocalStorageService";

const localStorageService = LocalStorageService.getService();

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*', 
      component: Home
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (router.app.$keycloak && router.app.$keycloak.authenticated) {
      if (to.meta.authRoles) {
        let hasRole = false
        for (var i = 0; i < to.meta.authRoles.length; i++) {
          if (router.app.$keycloak.hasResourceRole(to.meta.authRoles[i])) {
            hasRole = true;
          }
        }
        if (hasRole) {
          next()
        } else {
          router.push('unauthorized') 
        }
      } else {
        next();
      }
    } else {
      const url = localStorageService.getLoginUrl()
      window.location.replace(url)
    }
  } else {
    next()
  }
})

let announcer = document.getElementById('announcer');
router.afterEach(function(to) {
  if (!to.name) { return; }
  announcer.innerHTML = `${to.name} page has loaded`;
});

export default router