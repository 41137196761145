import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

const defaultState = {
        employees: [],
        userRole: {},
}

export const store = new Vuex.Store( {

    plugins: [
        createPersistedState({
            storage: {
              getItem: (key) => ls.get(key),
              setItem: (key, value) => ls.set(key, value),
              removeItem: (key) => ls.remove(key),
            },
          }),
    ],
    
    state: defaultState,

    actions: {
        resetState({commit}) {            
            commit('RESET_STATE')
        }

      },

      mutations: {
        RESET_STATE(state) {
            Object.assign(state, defaultState)
        }
      },
    
} )